import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'
import Meta from 'components/Meta'
import Sort from '../components/fun/sort'
import { Button } from '../components/Button'

class HomeIndex extends React.Component {
  render() {
    const { location, data } = this.props
    const logo = get(data, 'logo.childImageSharp.sizes')
    const wide3 = get(data, 'wide3.childImageSharp.sizes')
    const printapi = get(data, 'printapi.childImageSharp.fixed')
    const magcloud = get(data, 'magcloud.childImageSharp.fixed')
    const poyomi = get(data, 'poyomi.childImageSharp.fixed')
    const blurb = get(data, 'blurb.childImageSharp.fixed')
    const awsLogo = get(data, 'awsLogo.childImageSharp.fixed')
    const blurbLogo = get(data, 'blurbLogo.childImageSharp.fixed')
    const hpLogo = get(data, 'hpLogo.childImageSharp.fixed')
    const rtvLogo = get(data, 'rtvLogo.childImageSharp.fixed')

    return (
      <Layout location={location}>
        <Meta
          site={siteMetadata}
          title="While true d.o.o "
          googleMapsSrc={true}
        />
        <div>
          <section
            className="text-center"
            style={{
              background: `url(${wide3.src}) no-repeat center center scroll`,
              backgroundSize: 'cover',
            }}
          >
            <img
              className={'img-responsive img-center'}
              style={{ maxWidth: '100%' }}
              src={logo.src}
            />
          </section>

          <section className="pb-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="section-heading">While True</h1>

                  <p
                    className="lead section-lead"
                    style={{ textAlign: 'justify' }}
                  >
                    We build robust, secure and reliable software. 15+ years of
                    experience. Great track record.
                  </p>
                  <p style={{ textAlign: 'justify' }}>
                    We specialize in robust back-end software that is expected
                    to run for years without interruptions.
                    <br />
                    We are <strong>AWS (Amazon Web Services)</strong>{' '}
                    specialists and have been delivering application that run
                    for on it for {new Date().getFullYear() - 2010}+ years.
                    <br />
                  </p>

                  <p style={{ textAlign: 'justify' }}>
                    Our expertise is Java, .NET, Infrastructure as code, AWS
                    Lambda, agile, Docker, React, AWS ECS and all things AWS.
                  </p>
                  <p style={{ textAlign: 'justify' }} />
                </div>
                <div className="container">
                  <div className="row justify-content-between">
                    <div className="col-lg-9">
                      <div className="container pl-0 pr-0 ">
                        <h4 className="section-heading">Clients</h4>
                        <hr />
                        <div className="row">
                          <div className="col-md-4 col-6 pl-3">
                            <img src={hpLogo.src} alt="Hp logo" />
                          </div>
                          <div className="col-md-4 col-6 pl-3 text-center">
                            <img src={blurbLogo.src} alt="Blurb logo" />
                          </div>
                          <div className="col-md-4 col-6 pl-4">
                            <img src={rtvLogo.src} alt="Rtv logo" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                      <h4 className="section-heading">Partner</h4>
                      <hr />
                      <a
                        href="https://aws.amazon.com/partners/find/partnerdetails/?n=While%20True%2C%20d.o.o.&id=0010h00001aBgn8AAC"
                        target="_blank"
                      >
                        <img src={awsLogo.src} alt="While true partner badge" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="features" className="text-center portfolio">
            <br />
            <div className="container">
              <br />
              <div className="row">
                <div className="col-lg-12" style={{ textAlign: 'justify' }}>
                  <h1 className="section-heading">Portfolio highlights</h1>
                </div>
              </div>
              <div className="row">
                <div className="card-deck">
                  <div className="card col-sm-12 border-0 shadow">
                    <img
                      className="card-img-top"
                      src={printapi.src}
                      alt="Card image cap"
                    />

                    <div className="card-body">
                      <h5 className="card-title">Blurb print api</h5>
                      <p className="card-text text-center small">
                        {' '}
                        Complete system implementation on AWS: Cloudformation -
                        infrastructure as code, Microservices: Spring Boot, AWS
                        Lambda, AWS Aurora, React
                      </p>
                    </div>
                  </div>

                  <div className="card col-sm-12 border-0 shadow">
                    <img
                      className="card-img-top"
                      src={magcloud.src}
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h5 className="card-title">HP Labs MagCloud</h5>
                      <p className="card-text text-center small">
                        Complete implementation, multi-tier .NET application
                        running on AWS. SQL Server.
                      </p>
                      {/*<p className="card-text">*/}
                      {/*  <small className="text-muted">Last updated 3 mins ago</small>*/}
                      {/*</p>*/}
                    </div>
                  </div>
                </div>
                <div className="card-deck mt-3">
                  <div className="card col-sm-12  border-0 shadow">
                    <img
                      className="card-img-top"
                      src={poyomi.src}
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Photobook creation service</h5>
                      <p className="card-text text-center small">
                        {' '}
                        Complete project development, machine-learning, .NET,
                        NoSQL, RabbitMQ, Knockout. Datacenter.
                      </p>
                    </div>
                  </div>

                  <div className="card col-sm-12  border-0 shadow">
                    <img
                      className="card-img-top"
                      src={blurb.src}
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Blurb backend services</h5>
                      <p className="card-text text-center small">
                        {' '}
                        Spring Boot microservices, high-performance components
                        &amp; Ember.js front-end. AWS and datacenter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </section>

          <section className="text-center" id="concept">
            <a href="/contact">
              <Button>
                <h3 className="m-0 section-heading">Get in touch</h3>
              </Button>
            </a>{' '}
            <a href="/contact#jobs">
              <Button>
                <h3 className="m-0 section-heading">Careers</h3>
              </Button>
            </a>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex

export const query = graphql`
  query ProfilePageQuery {
    logo: file(name: { eq: "logo" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    wide3: file(name: { eq: "wide3" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    awsLogo: file(name: { eq: "aws-consulting-partner" }) {
      childImageSharp {
        fixed(width: 150, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    blurbLogo: file(name: { eq: "blurb-logo" }) {
      childImageSharp {
        fixed(width: 130, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    hpLogo: file(name: { eq: "hp-logo" }) {
      childImageSharp {
        fixed(width: 130, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    rtvLogo: file(name: { eq: "rtvslo-logo" }) {
      childImageSharp {
        fixed(width: 130, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    printapi: file(name: { eq: "print-api-2" }) {
      childImageSharp {
        fixed(width: 520, height: 320, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    magcloud: file(name: { eq: "magcloud-2" }) {
      childImageSharp {
        fixed(width: 520, height: 320, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    poyomi: file(name: { eq: "poyomi-ipad" }) {
      childImageSharp {
        fixed(width: 520, height: 320, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    blurb: file(name: { eq: "uploader-2" }) {
      childImageSharp {
        fixed(width: 520, height: 320, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
