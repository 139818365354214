import React from 'react'
import styled from 'styled-components'

const ButtonStyled = styled.button`
  background: #f38001;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px;
  :hover {
    background: #ff8b01;
  }
`

export const Button = ({ ...props }) => <ButtonStyled {...props} />
